/* eslint-disable import/no-named-as-default */
import {Route, Switch} from "react-router-dom";
import ScrollToTop from "../utils/scrollToTop"

import HomePage from "./HomePage";
import AmenityPage from "./AmenityPage";
import ActivityPage from "./ActivityPage";
import AttractionPage from "./AttractionPage";
import RulePage from "./RulePage";
import DirectionPage from "./DirectionPage";
import SiteMapPage from "./SiteMapPage";
import ReservationPage from "./ReservationPage";
import SeasonalPage from "./SeasonalPage";
import ContactPage from "./ContactPage";
import NotFoundPage from "./NotFoundPage";

import Navigation from "./partial/Navigation"
import Footer from "./partial/Footer"

import React from "react";
import PropTypes from "prop-types";
import {hot} from "react-hot-loader";

// This is a class-based component because the current
// version of hot reloading won't hot reload a stateless
// component at the top-level.

class App extends React.Component {
  constructor(props) {
    super(props)
  }
  render() {
    return (
      <div>
        <ScrollToTop />
        <Navigation />
        <main>
          <Switch>
            <Route exact path="/" component={HomePage}/>
            <Route exact path="/amenity" component={AmenityPage}/>
            <Route exact path="/activity" component={ActivityPage}/>
            <Route exact path="/attraction" component={AttractionPage}/>
            <Route exact path="/rule" component={RulePage}/>
            <Route exact path="/direction" component={DirectionPage}/>
            <Route exact path="/site-map" component={SiteMapPage}/>
            <Route exact path="/reservation" component={ReservationPage}/>
            <Route exact path="/seasonal" component={SeasonalPage}/>
            <Route exact path="/contact" component={ContactPage}/>
            <Route component={NotFoundPage}/>
          </Switch>
        </main>
        <Footer/>
      </div>
    );
  }
}

App.propTypes = {
  children: PropTypes.element
};

export default hot(module)(App);
