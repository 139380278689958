import Announcement from "./partial/Announcement"

import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import DatePicker from 'react-datepicker';
import ReactGA from 'react-ga'
import * as actions from '../actions/reservationActions';
import {reservationParameters} from '../types';

import "react-datepicker/dist/react-datepicker.css";

export class ReservationPage extends React.Component {
  constructor(props) {
    super(props)
    this.handleChange = this.handleChange.bind(this);
    this.handleArrivalDateChange = this.handleArrivalDateChange.bind(this);
    this.handleDepartureDateChange = this.handleDepartureDateChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange = (ev) => {
    this.props.actions.setReservationParameter(ev.target.name, ev.target.value);
  }
  handleArrivalDateChange = (date) => {
    this.props.actions.setReservationParameter("arrivalDate", date);
  }
  handleDepartureDateChange = (date) => {
    this.props.actions.setReservationParameter("departureDate", date);
  }

  handleSubmit = () => {
    ReactGA.event({
      category: "Submit Reservation",
      action: "User Tried to Submit Reservation Request.",
    });
    this.props.actions.submitReservation();
  }

  render() {
    return (
      <div id="reservation-page">
        <Announcement/>
        <section className="section">
          <div className="container">
            <div className="columns">
              <div className="column is-8">
                <div className="columns">
                  <div className="column is-full">
                    <h1 className="title">Reservation</h1>
                    <p>Red Rock Ponds RV Resort now offers the option of making your camping reservation online. Simply complete
                      the form below to start! We look forward to welcoming you to our park! Please don&apos;t hesitate to
                      contact us regarding any online booking issues you may encounter. We reserve the right to make adjustments
                      to your online booking, you will be notified of any changes made.</p><br/>
                    <p><strong>Contact us for monthly reservations if no availability is found online, we might be able to help!
                      Locking fees are waived for group reservations of 3 or more. </strong></p><br/>
                    <div className="notification is-info is-light mt-2">
                      Call us at <strong>(585) 638-2445</strong> should you experience any issue while booking online.
                    </div>
                  </div>
                </div>
                <div className="columns is-multiline">
                  <div className="column is-6">
                    <label className="label">Arrival<strong className="has-text-danger">*</strong></label>
                    <div className="field">
                      <div className="control">
                        <DatePicker className="input"
                                    selected={this.props.reservationParameters.fields.arrivalDate.value}
                                    onChange={this.handleArrivalDateChange}
                                    selectsStart
                                    startDate={this.props.reservationParameters.fields.arrivalDate.value}
                                    endDate={this.props.reservationParameters.fields.departureDate.value}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="column is-6"><label className="label">Departure<strong
                    className="has-text-danger">*</strong></label>
                    <div className="field">
                      <div className="control">
                        <DatePicker className="input"
                                    selected={this.props.reservationParameters.fields.departureDate.value}
                                    onChange={this.handleDepartureDateChange}
                                    selectsEnd
                                    startDate={this.props.reservationParameters.fields.arrivalDate.value}
                                    endDate={this.props.reservationParameters.fields.departureDate.value}
                                    minDate={this.props.reservationParameters.fields.arrivalDate.value}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <hr className="hr"/>
                <div className="columns is-multiline">
                  <div className="column is-4">
                    <div className="field">
                      <label className="label">Number of Adults<strong className="has-text-danger">*</strong></label>
                      <div className="control is-expanded">
                        <div
                          className={`select is-fullwidth ${this.props.reservationParameters.fields.numOfAdults.error ? "is-danger" : ""}`}>
                          <select name="numOfAdults" value={this.props.reservationParameters.fields.numOfAdults.value}
                                  onChange={this.handleChange}>
                            <option value="">&nbsp;&nbsp;&nbsp;</option>
                            <option value="1">1</option>
                            <option value="2">2</option>
                            <option value="3">3</option>
                            <option value="4">4</option>
                            <option value="5">5</option>
                            <option value="6">6</option>
                          </select>
                        </div>
                        <p
                          className={`help is-danger has-text-danger ${this.props.reservationParameters.fields.numOfAdults.error ? "" : "is-hidden"}`}>{this.props.reservationParameters.fields.numOfAdults.message}</p>
                      </div>
                    </div>
                  </div>
                  <div className="column is-4">
                    <div className="field">
                      <label className="label">Number of Children<strong
                        className="has-text-danger">*</strong></label>
                      <div className="control is-expanded">
                        <div
                          className={`select is-fullwidth ${this.props.reservationParameters.fields.numOfChildren.error ? "is-danger" : ""}`}>
                          <select name="numOfChildren"
                                  value={this.props.reservationParameters.fields.numOfChildren.value}
                                  onChange={this.handleChange}>
                            <option value="">&nbsp;&nbsp;&nbsp;</option>
                            <option value="0">0</option>
                            <option value="1">1</option>
                            <option value="2">2</option>
                            <option value="3">3</option>
                            <option value="4">4</option>
                            <option value="5">5</option>
                          </select>
                        </div>
                        <p
                          className={`help is-danger has-text-danger ${this.props.reservationParameters.fields.numOfChildren.error ? "" : "is-hidden"}`}>{this.props.reservationParameters.fields.numOfChildren.message}</p>
                      </div>
                    </div>
                  </div>
                  <div className="column is-4">
                    <div className="field">
                      <label className="label">Number of Pets<strong className="has-text-danger">*</strong></label>
                      <div className="control is-expanded">
                        <div
                          className={`select is-fullwidth ${this.props.reservationParameters.fields.numOfPets.error ? "is-danger" : ""}`}>
                          <select name="numOfPets" value={this.props.reservationParameters.fields.numOfPets.value}
                                  onChange={this.handleChange}>
                            <option value="">&nbsp;&nbsp;&nbsp;</option>
                            <option value="0">0</option>
                            <option value="1">1</option>
                            <option value="2">2</option>
                            <option value="3">3</option>
                            <option value="4">4</option>
                            <option value="5">5</option>
                          </select>
                        </div>
                        <p
                          className={`help is-danger has-text-danger ${this.props.reservationParameters.fields.numOfPets.error ? "" : "is-hidden"}`}>{this.props.reservationParameters.fields.numOfPets.message}</p>
                      </div>
                    </div>
                  </div>
                </div>
                <hr className="hr"/>
                <div className={`message
                  ${this.props.reservationParameters.validation.valid ? "is-hidden" : "is-danger"}`}>
                  <div className="message-body">
                    <div className="content">
                      <p>
                        {this.props.reservationParameters.validation.message}
                      </p>
                    </div>
                  </div>
                </div>
                <div className={`message
                  ${this.props.reservationParameters.response.type === "success" ? "is-success" : "is-danger"}
                  ${this.props.reservationParameters.response.type === "url" ? "is-hidden" : ""}
                  ${this.props.reservationParameters.response.type ? "" : "is-hidden"}
                  `}>
                  <div className="message-body">
                    <div className="content">
                      <p>
                        {this.props.reservationParameters.response.message}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="field is-grouped is-grouped-right">
                  <div className="control">
                    <button className="button is-link" onClick={this.handleSubmit}>Search</button>
                  </div>
                  <div className="control">
                    <button className="button is-link is-light">Cancel</button>
                  </div>
                </div>
              </div>
              <div className="column is-4">
                <article className="message is-success">
                  <div className="message-header">
                    <p>Rates</p>
                  </div>
                  <div className="message-body">
                    <p>2024 Seasons Runs:<br/>
                      <strong>May 3rd - Oct 20th</strong></p>
                    <br/>
                    <h5 className="title is-5">Daily Rates</h5>
                    <dl className="no-style">
                      <li><strong>Weekdays:</strong><span className="is-pulled-right">$50.00</span></li>
                      <li><strong>Weekends:</strong><span className="is-pulled-right">$62.00*</span></li>
                      <li><strong>Holidays:</strong><span className="is-pulled-right">$77.00*</span></li>
                    </dl>
                    <p>*Holidays refers to long weekends only (Memorial Day weekend etc). 3 day minimum on
                      holidays. Weekend rate may increase on selected dates.</p>
                    <hr className="hr"/>
                    <h5 className="title is-5">Weekly Rates</h5>
                    <dl className="no-style">
                      <li><strong>Regular:</strong><span className="is-pulled-right">$325*</span></li>
                      <li><strong>Peak (Jul-Aug):</strong><span className="is-pulled-right">$350*</span></li>
                    </dl>
                    <p>*Rate may increase by $25/day for dates overlapping long weekend holidays.</p>
                    <hr className="hr"/>
                    <h5 className="title is-5">Monthly Rates</h5>
                    <dl className="no-style">
                      <li><strong>Month (30 days):</strong><span className="is-pulled-right">$775 + Electric*</span>
                      </li>
                    </dl>
                    <p>*Rate may increase by $25/day for dates overlapping long weekend holidays.</p>
                  </div>
                </article>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }

  componentDidUpdate() {
    if (this.props.reservationParameters.response.type === "url") {
      window.location.assign("https://www.campspot.com/book/redrockpondsrvresort/search".concat(this.props.reservationParameters.response.message));
    }
  }
}

ReservationPage.propTypes = {
  actions: PropTypes.object.isRequired,
  reservationParameters: reservationParameters.isRequired
};

function mapStateToProps(state) {
  return {
    reservationParameters: state.reservationParameters
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(actions, dispatch)
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ReservationPage);
