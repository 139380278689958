import {SET_SITE_PARAMETERS, TOGGLE_NAV_MENU, CLOSE_NAV_MENU} from '../constants/actionTypes';
import objectAssign from 'object-assign';
import initialState from './initialState';

export default function siteParameterReducer(state = initialState.siteParameters, action) {
  let newState;

  switch (action.type) {
    case SET_SITE_PARAMETERS:
      newState = objectAssign({}, state);
      newState = action.siteParameters
      return newState;

    case TOGGLE_NAV_MENU:
      newState = objectAssign({}, state);
      newState.isNavMenuOpen  = !state.isNavMenuOpen
      return newState;

    case CLOSE_NAV_MENU:
      newState = objectAssign({}, state);
      newState.isNavMenuOpen  = false;
      return newState;

    default:
      return state;
  }
}
