import React from 'react';
import {connect} from 'react-redux';
import {siteParameters} from "../types"

import GoogleMapReact from 'google-map-react';


export class DirectionPage extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    return (
      <div id="direction-page">
        <section id="direction" className="section">
          <div className="container">
            <h1 className="title">Directions</h1>
            <h2 className="subtitle">We are located in Orleans county, greater Niagara region, in New York State.</h2>
            <p>We strongly recommend our visitors use the following directions. There are shorter routes, but many of
              the bridges crossing the Erie Canal are old, very narrow and in some cases impossible to cross with a
              large vehicle. These directions are specifically designed to provide the best travel routes for large
              RVs. </p>
            <hr className="hr"/>
            <div className="columns">
              <div className="column is-6">
                <div id="map">
                  <GoogleMapReact
                    bootstrapURLKeys={{key: this.props.siteParameters.map.key}}
                    defaultCenter={this.props.siteParameters.map.center}
                    defaultZoom={this.props.siteParameters.map.zoom}
                  >
                    <i lat={43.255249} lng={-78.077096} id="map-marker"
                       className="fa fa-map-marker has-text-success is-size-1 has-text-centered"/>
                  </GoogleMapReact>
                </div>
              </div>
              <div className="column is-6">
                <article className="message is-success">
                  <div className="message-body">
                    <h5 className="title is-5">Address</h5>
                    <dl className="no-style">
                      <li><strong>Address:</strong><span
                        className="is-pulled-right">16097 Canal Rd., Holley, NY 14470</span></li>
                    </dl>
                    <hr className="hr"/>
                    <h5 className="title is-5">Directions from the East (Rochester):</h5>
                    <ol type="1">
                      <li>Take I-490 West, to Route 531 West, then to Route 31 West.</li>
                      <li>Turn right onto Sweden Walker Road / Route 260 North.</li>
                      <li>Go 2 miles and turn left onto Ridge Road / Route 104 West. Go 11 miles and turn left onto Fancher Road.</li>
                      <li>Go 2.2 miles and turn left on to Canal Road.</li>
                      <li>Enter at 16097 Canal Road.</li>
                    </ol>
                    <hr className="hr"/>
                    <h5 className="title is-5">Directions from the West (Buffalo):</h5>
                    <ol type="1">
                      <li>Take I-90 East to Exit 48 in Batavia.</li>
                      <li>Turn right onto Route 98 North. Go 19 miles and turn right onto Ridge Road / Route 104 East.</li>
                      <li>Go 5 miles and turn right onto Fancher Road. Go 2.2 miles and turn left onto Canal Road.</li>
                      <li>Enter at 16097 Canal Road.</li>
                    </ol>
                  </div>
                </article>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

DirectionPage.propTypes = {
  siteParameters: siteParameters.isRequired
};

function mapStateToProps(state) {
  return {
    siteParameters: state.siteParameters
  };
}

export default connect(
  mapStateToProps
)(DirectionPage);
