import React from 'react';

const RulePage = () => {
  return (
    <div>
      <section className="section">
        <div className="container">
          <div className="content">
            <h1>Rules & Information</h1>
            <p>These rules are in place for your safety and the well-being of those around you.
              Please respect these rules and the other campers. </p>
            <h2>Rules</h2>
            <h4>Campground</h4>
            <ol>
              <li><strong>SPEED LIMIT</strong> - is 5 MPH. Offenders will be removed with no refund.</li>
              <li><strong>QUIET HOURS</strong> - are between 10:00 pm and 8:00 am. NO loud noises or loud music
                permitted during quiet hours.
              </li>
              <li><strong>GATE PASS</strong> - is for registered campers only. Do not use your pass to let in visitors
                during business hours. After hour visitors must be registered the next business day. Violators will be
                fined and/or removed. Gate allows one vehicle at a time, do not tailgate.
              </li>
              <li><strong>ALCOHOL & DRUGS</strong> - Roaming the grounds with alcohol is not encouraged. Underage
                drinking is prohibited. Drugs are prohibited. No foul language will be tolerated.
              </li>
              <li><strong>SITE ASSIGNMENT</strong> - Changing campsites without permission is prohibited.</li>
              <li><strong>USE OF CAMPSITE</strong> – Two (2) Adults, three (3) children under 18, and a maximum of 2
                pets are permitted per site. Additional guests will be charged $5.00 per night. One (1) camping unit per
                site. Tents for lodging purposes can be set up with prior permission at $25 per small (2 person) tent
                per night
                and $35 per large (3+ people) tent per night. Only persons allowed to use the site are those who rented
                it. No
                family members or guest will have use of site if original renters are not present.
              </li>
              <li><strong>CAMPING ETIQUETTE</strong> - Campsites must be kept neat, orderly and free from trash. Do not
                leave trash outside overnight. Trash must be taken to the dumpsters. Respect your neighbors. Do not
                walk-through other sites.
              </li>
              <li><strong>VEHICLES & GOLF CARTS</strong> - Motorized vehicles of any type may only be operated by person
                with valid driver license. Do not park vehicles on grass in wet conditions. Only electric golf carts are
                allowed, insurance certificate is required for golf carts. Golf carts with no lights cannot be driven
                after dark.
              </li>
              <li><strong>SEWER</strong> - There must be a sealed connection on your sewer hook-up. No hoses dropped in
                will be allowed.
              </li>
              <li><strong>VISITORS</strong> - must stop at front office to register. A visitor fee of $5.00 per adult
                and $3.00 per child under the age of 16 will be charged and you will be solely responsible for your
                visitor’s actions. A visitor’s pass must be visible at all times. If a visitor fails to register or does
                not show a valid visitor’s pass, they will be asked to leave the premises.
              </li>
              <li><strong>PETS</strong> - must be on leash and under control at all times. Be considerate of your
                neighbors and cleaned up after every poop. Do not leave pets unattended. Any pet causing a disturbance
                will not be allowed to stay. Pets are not allowed in any buildings, the ponds or playground area. Do not
                tie pets to the trees in any way. Proof of a valid rabies certificate must be provided before any dog is
                allowed in a campground. Dispose pet waste in main dumpster only. No Pit Bulls or other vicious breed
                dogs will be allowed in the park.
              </li>
              <li><strong>CAMPFIRE</strong> - allowed only within the fire ring provided or your own fire pit. Campfires
                must be kept under control and never left unattended. Fire must be out before turning in for the night.
                Do not move fire ring. Do not burn garbage in fire rings.
              </li>
              <li><strong>TREES</strong> - Cutting, nailing (decorations, name plaques etc.), or damaging any of the
                trees or plant life is prohibited. Climbing trees is prohibited. No hammocks tied to trees. A
                free-standing hammock is ok.
              </li>
              <li><strong>CHILDREN</strong> - Parents are responsible for the safety and actions of their children.
                Children are not allowed in the play area or beach area after posted closing hours. Children less than 8
                years old must be accompanied to the restrooms. Vandalism and disrespect for persons or property will
                not be tolerated.
              </li>
              <li><strong>BICYLCE</strong> - NYS Bicycle Safety Laws must be followed. These laws include: helmet use,
                safe speeds, and no riding after dusk. ATVs, Skateboards, razor scooters and rollerblades are not
                permitted.
              </li>
              <li><strong>FACILITIES</strong> - No dishwashing in restrooms. No smoking in any of the buildings; this
                includes- E-Cigarettes. Do not throw butts on the grounds.
              </li>
              <li><strong>LAUNDRY</strong> - Clotheslines are not permitted at the campsites. Do not use your awning for
                a clothesline. Laundry facilities are available for your convenience. Laundry facility is open 24 hours.
                Please do not leave clothes in washer and dryers. Change is available at the office.
              </li>
              <li><strong>STAKES</strong> – should be avoided due to underground utilities.</li>
              <li><strong>INFLATABLE POOLS</strong> – are not permitted due to safety concerns and insurance
                limitations.
              </li>
            </ol>
            <h4>Reservation</h4>
            <ol type="1">
              <li>Check-in time is 1:00 PM, and no later than 10:00 PM in the evening. Early check-ins available for a
                fee.
              </li>
              <li>Check-out time is 11:00 AM. Late check-outs available for a fee.
              </li>
              <li>Reservations are not transferable without prior approval.</li>
              <li>The minimum age to rent and occupy a site is 21 years of age.</li>
              <li>Campsite rental location is subject to change. While we make every effort to
                ensure you are placed on the site number reserved, a reservation of a specific site number is not
                guaranteed. We reserve the right to place you on any campsite.
              </li>
            </ol>
            <h4>Beach & Playground Areas</h4>
            <ol type="1">
              <li>Swimming in designated beach area only.</li>
              <li>Per New York state law, children under 16 years of age must be accompanied by two parents or adults
                over 18 years of age at the
                swimming area. One adult must stay on the beach at all times.
              </li>
              <li>Children under 16 years of age must be supervised at the playground.</li>
              <li>No one is allowed at the beach or play area after dusk.</li>
              <li>Glass bottles and alcoholic containers are not allowed at the playground or swim area.</li>
              <li>No rock or sand throwing will be tolerated.</li>
              <li>You must sign out the boats and life jackets in the office before using them. All persons are required
                to wear a life jacket when using all water craft.
              </li>
            </ol>
            <h2>Information</h2>
            <h4>Fees & Payment</h4>
            <ol type="1">
              <li>Rates include two (2) adults, three (3) children under 18. Additional persons will be charged at $5.00
                per person per night.
              </li>
              <li>A visitor fee of $5.00 per adult and $3.00 per child under the age of 16 will be charged up on entry.
              </li>
              <li>A fee of $25/$35 per tent per night will be charged for erecting small or family sized tents.
              </li>
              <li>A valid credit card is required to make a reservation.</li>
              <li>Price subject to change if your reservation is made prior to season start.</li>
            </ol>
            <h4>Cancellation Policy</h4>
            <ol type="1">
              <li>No refunds for early departure.</li>
              <li>Cancellations of a holiday reservation will receive no refund.</li>
            </ol>
            <h6>Short-term Reservations:</h6>
            <ol type="1">
              <li>Cancellations with <strong>more</strong> than 10-days of notice will receive a refund of deposit less
                $15 fee.
              </li>
              <li>Cancellations with 10-day or <strong>less</strong> of notice will receive a refund of deposit less
                one night at prevailing daily rate.
              </li>
            </ol>
            <h6>Long-term (weekly/monthly) Reservations:</h6>
            <ol type="1">
              <li>Cancellations with <strong>more</strong> than 10-days of notice will receive a refund of deposit less
                $25 fee per week or per month according to reservation type.
              </li>
              <li>Cancellations with 10-day or <strong>less</strong> of notice will receive no refund.
              </li>
            </ol>
            <h2>Liability and Authorizations </h2>
            <p>The owner/management of Red Rock Ponds RV Resort absolve themselves from any and all liability and
              responsibility pertaining to the loss by fire, theft, property damage, accident or any other cause
              whatsoever. The owner/management also has the right to refuse any RV, camper or tent.

              I hereby authorize Red Rock Ponds RV Resort to publish photographs taken of me or family members for use
              in publications and on the Red Rock Ponds web site. I acknowledge that my participation is voluntary and I
              will receive no financial compensation. I release Red Rock Ponds RV Resort, its owners and employees from
              liability from any claims by me or any third party in connection with my participation.</p>
            <h2>Statement of Privacy</h2>
            <p>The personal information collected by Red Rock Ponds RV Resort will be used for the purposes of
              reservations, park administration, management and park enforcement. Red Rock Ponds RV Resort does not
              collect personally identifiable information about individuals except when individuals specifically provide
              such information on a voluntary basis. For example, personal information may be gathered in connection
              with the campsite reservation process. Personally identifiable information will not be sold or otherwise
              transferred to unaffiliated third parties. </p>
            <h2>Cookies</h2>
            <p>Users should be aware that non-personal data may be automatically collected through the use of “cookies”.
              “Cookies” are small text files that are used to recognize the user’s ongoing access to the site. Cookies
              do not have the ability to attach to a user’s system and damage files. If a user does not want information
              collected through the use of cookies, there is a simple procedure in most browsers that allows the user to
              deny or accept the cookie feature. However, if your browser is not set to accept cookies you will be
              unable to access the online reservation service. </p>
            <h2>Privacy and Security</h2>
            <p>When you make a campsite reservation with Red Rock Ponds RV Resort you entrust us with your personal and
              financial information. Red Rock Ponds RV Resort has taken every precaution to safeguard your personal
              information and ensure that it is used appropriately.

              Credit Card Transaction Security has been implemented to ensure the safety of your reservation, personal,
              and credit card information. This has been done through the use of certificates, multiple methods and
              levels of data encryption such as Secure Socket Layers (amongst others), as well as other techniques.</p>
            <h2>Certificates</h2>
            <p>Red Rock Ponds RV Resort uses certificates on its website to increase security and to demonstrate that
              your reservation transaction is safe. Certificates are digital documents that authenticate individuals or
              organizations. Certificates are typically used to generate confidence in the legitimacy of a certificate
              holder. They allow verification that we are who we say we are (Red Rock Ponds RV Resort and our service
              provider). Certificates are digital signatures that protect public keys (our unique identifier) from
              forgery, false representation, or alteration. Certificates are issued by a certifying authority (CA)
              willing to vouch for the identities of those to whom it issues certificates.</p>
            <h2>Secure and Encrypted Communications</h2>
            <p>Secure Socket Layers are a method encrypting and decrypting your data transmissions over the internet to
              our site. It ensures that no unauthorized parties can “steal” or “see” your data as it makes its way to
              our site. This is done by jumbling the data into unrecognizable bits that only Red Rock Ponds RV Resort
              can unjumble on its site based on a secure “digital key”. </p>
          </div>
        </div>
      </section>
    </div>
  );
};

export default RulePage;
