export default {
  siteParameters: {
    isNavMenuOpen: false,
    map: {
      key: "AIzaSyA76UtUQeU8lISWnLw_bXxlI25dinYa4xo",
      center: {
        lat: 43.26,
        lng: -78.08
      },
      zoom: 11
    }
  },
  reservationParameters: {
    type: "reservation",
    fields: {
      arrivalDate: {value:new Date(), isRequired:true, error: false, message: ""},
      departureDate: {value:new Date(), isRequired:true, error: false, message: ""},
      numOfAdults: {value: "", isRequired:true, error: false, message: ""},
      numOfChildren: {value: "", isRequired:true, error: false, message: ""},
      numOfPets: {value: "", isRequired:true, error: false, message: ""},
    },
    validation: {valid: true, message: ""},
    response: {type: "", message: ""},
  },
  seasonalParameters: {
    type: "seasonal",
    fields: {
      firstName: {value:"", isRequired:true, error: false, message: ""},
      lastName: {value:"", isRequired:true, error: false, message: ""},
      phone: {value:"", isRequired:true, error: false, message: ""},
      email: {value:"", isRequired:true, error: false, message: ""},
      address: {value:"", isRequired:true, error: false, message: ""},
      city: {value:"", isRequired:true, error: false, message: ""},
      state: {value:"", isRequired:true, error: false, message: ""},
      zip: {value:"", isRequired:true, error: false, message: ""},
      makeOfRV: {value:"", isRequired:true, error: false, message: ""},
      modelOfRV: {value: "", isRequired:true, error: false, message: ""},
      yearOfRV: {value:"", isRequired:true, error: false, message: ""},
      request: {value:"", error: false, message: ""},
    },
    validation: {valid: true, message: ""},
    response: {type: "", message: ""},
  },
  contactParameters: {
    type: "contact",
    fields: {
      name: {value:"", isRequired:true, error: false, message: ""},
      email: {value:"", isRequired:true, error: false, message: ""},
      phone: {value:"", error: false, message: ""},
      message: {value:"", isRequired:true, error: false, message: ""},
    },
    validation: {valid: true, message: ""},
    response: {type: "", message: ""},
  },


};
