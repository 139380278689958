/**
 * Created by: Max on 2/28/2020.
 * Description:
 */

import React from 'react';
import {NavLink, Link} from "react-router-dom";
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import * as actions from '../../actions/siteParameterActions';
import {siteParameters} from '../../types';

import navLogo from "../../images/logo_long.png"

export class Navigation extends React.Component {
  toggleNavMenu = () => {
    this.props.actions.toggleNavMenu();
  }
  closeNavMenu = () => {
    this.props.actions.closeNavMenu();
  }
  render() {
    return (
      <nav className="navbar is-success is-fixed-top">
        <div className="navbar-brand">
          <Link className="navbar-item navbar-logo" to="/">
            <img src={navLogo} alt="Logo"/>
          </Link>
          <div className={'navbar-burger burger' + ( this.props.siteParameters.isNavMenuOpen ? ' is-active' : '' )} data-target="navbar" onClick={this.toggleNavMenu}>
            <span></span>
            <span></span>
            <span></span>
          </div>
        </div>

        <div id="navbar" className={'navbar-menu' + ( this.props.siteParameters.isNavMenuOpen ? ' is-active' : '' )}>
          <div className="navbar-end">
            <NavLink className="navbar-item" exact to="/" activeClassName="is-active" onClick={this.closeNavMenu}>Home</NavLink>
            <div className="navbar-item has-dropdown is-hoverable">
              <a className="navbar-link">Resort</a>
              <div className="navbar-dropdown">
                <NavLink className="navbar-item" exact to="/amenity" activeClassName="is-active" onClick={this.closeNavMenu}>Amenities</NavLink>
                <NavLink className="navbar-item" exact to="/activity" activeClassName="is-active" onClick={this.closeNavMenu}>Activities</NavLink>
                <NavLink className="navbar-item" exact to="/attraction" activeClassName="is-active" onClick={this.closeNavMenu}>Attractions</NavLink>
                <NavLink className="navbar-item" exact to="/rule" activeClassName="is-active" onClick={this.closeNavMenu}>Rules</NavLink>
                <NavLink className="navbar-item" exact to="/direction" activeClassName="is-active" onClick={this.closeNavMenu}>Direction</NavLink>
              </div>
            </div>
            <NavLink className="navbar-item" exact to="/site-map" activeClassName="is-active" onClick={this.closeNavMenu}>Site Map</NavLink>
            <NavLink className="navbar-item" exact to="/reservation" activeClassName="is-active" onClick={this.closeNavMenu}>Reservation</NavLink>
            <NavLink className="navbar-item" exact to="/seasonal" activeClassName="is-active" onClick={this.closeNavMenu}>Seasonal</NavLink>
            <NavLink className="navbar-item" exact to="/contact" activeClassName="is-active" onClick={this.closeNavMenu}>Contact</NavLink>
            <span className="navbar-item">
                <NavLink className="button is-white is-outlined" exact to="/reservation">
                  <span className="icon">
                      <i className="fa fa-book"></i>
                  </span>
                  <span title="Hello from the other side">Book Now</span>
                </NavLink>
              </span>
          </div>
        </div>
      </nav>
    );
  }
}

Navigation.propTypes = {
  actions: PropTypes.object.isRequired,
  siteParameters: siteParameters.isRequired
};

function mapStateToProps(state) {
  return {
    siteParameters: state.siteParameters
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(actions, dispatch)
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Navigation);
