import * as types from '../constants/actionTypes';
import axios from 'axios';

export function setSeasonalParameter(name, value) {
  return function (dispatch) {
    return dispatch({
      type: types.SET_SEASONAL_PARAMETER,
      name,
      value
    });
  };
}

export function setSeasonalResponse(response) {
  return function (dispatch) {
    return dispatch({
      type: types.SET_SEASONAL_RESPONSE,
      response
    });
  };
}

export function validateSeasonal() {
  return function (dispatch) {
    return dispatch({
      type: types.VALIDATE_SEASONAL
    });
  };
}

export function submitSeasonal() {
  return function (dispatch, getState) {
    dispatch(setSeasonalResponse({type:"", response:""}));
    dispatch(validateSeasonal());
    if (getState().seasonalParameters.validation.valid) {
      axios.post('https://gerbil.redrockponds.com/send', {
        type: getState().seasonalParameters.type,
        fields: getState().seasonalParameters.fields,
      })
      .then(function (res) {
        dispatch(setSeasonalResponse(res.data.response));
      })
      .catch(function (error) {
        dispatch(setSeasonalResponse(error.response.data.response));
      });
    }
  };
}
