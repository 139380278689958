import { combineReducers } from 'redux';
import siteParameters from "./siteParameterReducer"
import reservationParameters from "./reservationReducer"
import seasonalParameters from "./seasonalReducer"
import contactParameters from "./contactReducer"
import { connectRouter } from 'connected-react-router'

const rootReducer = history => combineReducers({
  router: connectRouter(history),
  siteParameters,
  reservationParameters,
  seasonalParameters,
  contactParameters
});

export default rootReducer;
