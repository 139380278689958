import * as types from '../constants/actionTypes';

export function setSiteParameters(parameters) {
  return function (dispatch) {
    return dispatch({
      type: types.SET_SITE_PARAMETERS,
      parameters
    });
  };
}

export function toggleNavMenu() {
  return function (dispatch) {
    return dispatch({
      type: types.TOGGLE_NAV_MENU
    })
  }
}
export function closeNavMenu() {
  return function (dispatch) {
    return dispatch({
      type: types.CLOSE_NAV_MENU
    })
  }
}
