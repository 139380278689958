import React from 'react';

import siteMapImg from "../images/site-map-page/site_map_2021.png"

const SiteMapPage = () => {
  return (
    <div>
      <section className="section">
        <div className="container">
          <div className="columns">
            <div className="column is-9">
              <h1 className="title">Site Map</h1>
              <figure className="image is-marginless">
                <img src={siteMapImg} alt="Site Map"/>
              </figure>
            </div>
            <div className="column is-3">
              <article className="message is-success">
                <div className="message-header">
                  <p>Information</p>
                </div>
                <div className="message-body">
                  <h2 className="title is-5">Contact</h2>
                  <dl className="no-style">
                    <li><strong>Website:</strong><span className="is-pulled-right">redrockponds.com</span></li>
                    <li><strong>Address:</strong><span
                      className="is-pulled-right">16097 Canal Rd., Holley, NY 14470</span></li>
                    <li><strong>Phone:</strong><span className="is-pulled-right"><a
                      href="tel:585-638-2445">(585) 638-2445</a></span></li>
                    <li><strong>Email:</strong><span className="is-pulled-right"><a
                      href="mailto:contactus@redrockponds.com">contactus@redrockponds.com</a></span></li>
                  </dl>
                  <hr className="hr"/>
                  <h2 className="title is-5">Hours</h2>
                  <dl className="no-style">
                    <li><strong>Sun-Thu</strong><span className="is-pulled-right">10 AM - 4 PM</span></li>
                    <li><strong>Fri</strong><span className="is-pulled-right">10 AM - 7 PM</span></li>
                    <li><strong>Sat</strong><span className="is-pulled-right">10 AM - 6 PM</span></li>
                  </dl>
                  <hr className="hr"/>
                  <h2 className="title is-5">Campsites</h2>
                  <p>Campsites in white are available for rent. All sites are full hookup up to 50A including cable and
                    WiFi.</p>
                </div>
              </article>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default SiteMapPage;
