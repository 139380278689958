import React from 'react';

const Announcement = () => {
  return (
    <div>
      <div id="news-bar" className="box cta mb-0">
        <p className="has-text-centered has-text-weight-semibold">
          <span className="tag is-info">2024 SEASON</span> We Will Open for the Season May 3rd, 2024. Make Your Reservation Now!
        </p>
      </div>
    </div>
  );
};

export default Announcement;
