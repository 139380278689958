import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import ReactGA from 'react-ga'
import * as actions from '../actions/contactActions';
import {siteParameters, contactParameters} from '../types';

import GoogleMapReact from 'google-map-react';

export class ContactPage extends React.Component {
  constructor(props) {
    super(props)
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange = (ev) => {
    this.props.actions.setContactParameter(ev.target.name, ev.target.value);
  }

  handleSubmit = () => {
    ReactGA.event({
      category: "Submit Contact Form",
      action: "User Tried to Submit Contact Form.",
    });
    this.props.actions.submitContact();
  }

  render() {
    return (
      <div id="contact-page">
        <section id="contact" className="section">
          <div className="container">
            <h1 className="title">Contact Us</h1>
            <h2 className="subtitle">We are always here to help. Give us a call, write us a email. Or use the form below.</h2>
            <div className="columns">
              <div className="column is-6">
                <div className="mb-3">
                  <article className="message is-success">
                    <div className="message-body">
                      <h5 className="title is-5">Contact</h5>
                      <dl className="no-style">
                        <li><strong>Website:</strong><span className="is-pulled-right">redrockponds.com</span></li>
                        <li><strong>Address:</strong><span
                          className="is-pulled-right">16097 Canal Rd., Holley, NY 14470</span></li>
                        <li><strong>Phone:</strong><span className="is-pulled-right"><a href="tel:585-638-2445">(585) 638-2445</a></span></li>
                        <li><strong>Email:</strong><span className="is-pulled-right"><a href="mailto:contactus@redrockponds.com">contactus@redrockponds.com</a></span></li>
                      </dl>
                      <hr className="hr"/>
                      <h5 className="title is-5">Hours</h5>
                      <dl className="no-style">
                        <li><strong>Sun-Thu</strong><span className="is-pulled-right">10 AM - 4 PM</span></li>
                        <li><strong>Fri</strong><span className="is-pulled-right">10 AM - 7 PM</span></li>
                        <li><strong>Sat</strong><span className="is-pulled-right">10 AM - 6 PM</span></li>
                      </dl>
                    </div>
                  </article>
                </div>
                <div id="map">
                  <GoogleMapReact
                    bootstrapURLKeys={{ key: this.props.siteParameters.map.key }}
                    defaultCenter={this.props.siteParameters.map.center}
                    defaultZoom={this.props.siteParameters.map.zoom}
                  >
                    <i lat={43.255249} lng={-78.077096} id="map-marker" className="fa fa-map-marker has-text-success is-size-1 has-text-centered" />
                  </GoogleMapReact>
                </div>
              </div>
              <div className="column is-6">
                <div className="columns is-multiline">
                  <div className="column is-12">
                    <div className="field">
                      <label className="label">Name<strong className="has-text-danger">*</strong></label>
                      <div className="control has-icons-right">
                        <input
                          className={`input ${this.props.contactParameters.fields.name.error ? "is-danger" : ""}`}
                          type="text" placeholder="Full Name" name="name"
                          value={this.props.contactParameters.fields.name.value} onChange={this.handleChange}/>
                        <span className="icon is-small is-right">
                        <i
                          className={`fa fa-exclamation-triangle has-text-danger ${this.props.contactParameters.fields.name.error ? "" : "is-invisible"}`}/>
                      </span>
                      </div>
                      <p
                        className={`help is-danger has-text-danger ${this.props.contactParameters.fields.name.error ? "" : "is-hidden"}`}>{this.props.contactParameters.fields.name.message}</p>
                    </div>
                  </div>
                  <div className="column is-12">
                    <div className="field">
                      <label className="label">Email<strong className="has-text-danger">*</strong></label>
                      <div className="control has-icons-left has-icons-right">
                        <input
                          className={`input ${this.props.contactParameters.fields.email.error ? "is-danger" : ""}`}
                          type="email" placeholder="Email" name="email"
                          value={this.props.contactParameters.fields.email.value} onChange={this.handleChange}/>
                        <span className="icon is-small is-left">
                        <i className="fa fa-envelope"></i>
                      </span>
                        <span className="icon is-small is-right">
                        <i
                          className={`fa fa-exclamation-triangle has-text-danger ${this.props.contactParameters.fields.email.error ? "" : "is-invisible"}`}/>
                      </span>
                      </div>
                      <p
                        className={`help is-danger has-text-danger ${this.props.contactParameters.fields.email.error ? "" : "is-hidden"}`}>{this.props.contactParameters.fields.email.message}</p>
                    </div>
                  </div>
                  <div className="column is-12">
                    <div className="field">
                      <label className="label">Phone</label>
                      <div className="control has-icons-left has-icons-right">
                        <input
                          className={`input ${this.props.contactParameters.fields.phone.error ? "is-danger" : ""}`}
                          type="tel" placeholder="Phone" name="phone"
                          value={this.props.contactParameters.fields.phone.value} onChange={this.handleChange}/>
                        <span className="icon is-small is-left">
                        <i className="fa fa-phone"></i>
                      </span>
                        <span className="icon is-small is-right">
                        <i
                          className={`fa fa-exclamation-triangle has-text-danger ${this.props.contactParameters.fields.phone.error ? "" : "is-invisible"}`}/>
                      </span>
                      </div>
                      <p
                        className={`help is-danger has-text-danger ${this.props.contactParameters.fields.phone.error ? "" : "is-hidden"}`}>{this.props.contactParameters.fields.phone.message}</p>
                    </div>
                  </div>
                </div>
                <hr className="hr"/>
                <div className="columns is-multiline">
                  <div className="column is-12">
                    <div className="field">
                      <label className="label">Message<strong className="has-text-danger">*</strong></label>
                      <div className="control">
                        <textarea className={`textarea ${this.props.contactParameters.fields.name.error ? "is-danger" : ""}`} placeholder="Your message"
                                  name="message"
                                  value={this.props.contactParameters.fields.message.value}
                                  onChange={this.handleChange}/>
                      </div>
                      <p
                        className={`help is-danger has-text-danger ${this.props.contactParameters.fields.message.error ? "" : "is-hidden"}`}>{this.props.contactParameters.fields.message.message}</p>
                    </div>
                  </div>
                </div>
                <hr className="hr"/>
                <div className="columns is-multiline">
                  <div className="column is-12">
                    <div className={`message
                  ${this.props.contactParameters.validation.valid ? "is-hidden" : "is-danger"}`}>
                      <div className="message-body">
                        <div className="content">
                          <p>
                            {this.props.contactParameters.validation.message}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className={`message
                  ${this.props.contactParameters.response.type === "success" ? "is-success" : "is-danger"}
                  ${this.props.contactParameters.response.type ? "" : "is-hidden"}
                  `}>
                      <div className="message-body">
                        <div className="content">
                          <p>
                            {this.props.contactParameters.response.message}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="field is-grouped is-grouped-right">
                      <div className="control">
                        <button className="button is-link" onClick={this.handleSubmit}>Send</button>
                      </div>
                      <div className="control">
                        <button className="button is-link is-light">Cancel</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

ContactPage.propTypes = {
  actions: PropTypes.object.isRequired,
  siteParameters: siteParameters.isRequired,
  contactParameters: contactParameters.isRequired
};

function mapStateToProps(state) {
  return {
    siteParameters: state.siteParameters,
    contactParameters: state.contactParameters
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(actions, dispatch)
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ContactPage);
