// Centralized propType definitions
import { shape, number, bool, string, oneOfType, instanceOf } from 'prop-types';

export const savings = shape({
  monthly: oneOfType([number,string]),
  annual: oneOfType([number,string]),
  threeYear: oneOfType([number,string]),
});

export const siteParameters = shape({
  isNavMenuOpen: bool,
  map: shape({
    key: string,
    center: shape({
      lat: number,
      lng: number
    }),
    zoom: number
  })
});

export const reservationParameters = shape({
  type: string,
  fields: shape({
    arrivalDate: shape({value:instanceOf(Date), isRequired:bool, error: bool, message: string}),
    departureDate: shape({value:instanceOf(Date), isRequired:bool, error: bool, message: string}),
    numOfAdults: shape({value:oneOfType([number,string]), isRequired:bool, error: bool, message: string}),
    numOfChildren: shape({value:oneOfType([number,string]), isRequired:bool, error: bool, message: string}),
    numOfPets: shape({value:oneOfType([number,string]), isRequired:bool, error: bool, message: string}),
  }),
  validation: shape({valid: bool, message: string}),
  response: shape({type: string, message: string}),
})

export const seasonalParameters = shape({
  type: string,
  fields: shape({
    firstName: shape({value:string, isRequired:bool, error: bool, message: string}),
    latsName: shape({value:string, isRequired:bool, error: bool, message: string}),
    phone: shape({value:oneOfType([number,string]), isRequired:bool, error: bool, message: string}),
    email: shape({value:string, isRequired:bool, error: bool, message: string}),
    address: shape({value:string, isRequired:bool, error: bool, message: string}),
    city: shape({value:string, isRequired:bool, error: bool, message: string}),
    state: shape({value:string, isRequired:bool, error: bool, message: string}),
    zip: shape({value:oneOfType([number,string]), isRequired:bool, error: bool, message: string}),
    makeOfRV: shape({value:string, isRequired:bool, error: bool, message: string}),
    modelOfRV: shape({value:oneOfType([number,string]), isRequired:bool, error: bool, message: string}),
    yearOfRV: shape({value:oneOfType([number,string]), isRequired:bool, error: bool, message: string}),
    request: shape({value:string, isRequired:bool, error: bool, message: string}),
  }),
  validation: shape({valid: bool, message: string}),
  response: shape({type: string, message: string}),
})

export const contactParameters = shape({
  type: string,
  fields: shape({
    name: shape({value:string, isRequired:bool, error: bool, message: string}),
    email: shape({value:string, isRequired:bool, error: bool, message: string}),
    phone: shape({value:oneOfType([number,string]), isRequired:bool, error: bool, message: string}),
    message: shape({value:string, isRequired:bool, error: bool, message: string}),
  }),
  validation: shape({valid: bool, message: string}),
  response: shape({type: string, message: string}),
})
