import * as types from '../constants/actionTypes';
import axios from 'axios';

export function setContactParameter(name, value) {
  return function (dispatch) {
    return dispatch({
      type: types.SET_CONTACT_PARAMETER,
      name,
      value
    });
  };
}

export function setContactResponse(response) {
  return function (dispatch) {
    return dispatch({
      type: types.SET_CONTACT_RESPONSE,
      response
    });
  };
}

export function validateContact() {
  return function (dispatch) {
    return dispatch({
      type: types.VALIDATE_CONTACT
    });
  };
}

export function submitContact() {
  return function (dispatch, getState) {
    dispatch(setContactResponse({type:"", response:""}));
    dispatch(validateContact());
    if (getState().contactParameters.validation.valid) {
      axios.post('https://gerbil.redrockponds.com/send', {
        type: getState().contactParameters.type,
        fields: getState().contactParameters.fields,
      })
      .then(function (res) {
        dispatch(setContactResponse(res.data.response));
      })
      .catch(function (error) {
        dispatch(setContactResponse(error.response.data.response));
      });
    }
  };
}
