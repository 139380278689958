import React from 'react';

const AmenityPage = () => {
  return (
    <div id="amenity-page">
      <section className="hero is-medium is-primary is-bold">
        <div className="hero-body">
          <div className="container">
            <h1 className="title has-text-centered is-2">
              A Tranquil Getaway on the Erie Canal
            </h1>
            <h2 className="subtitle has-text-centered has-text-weight-medium is-family-secondary">
              Your summer hideout!
            </h2>
          </div>
        </div>
      </section>
      <section id="highlights" className="section">
        <div className="container">
          <div id="intro">
            <p className="has-text-justified has-text-weight-semibold">Whether it’s bass fishing in a tranquil pond,
              relaxing by the fire under a canopy of hardwoods, or exploring the rich history of upstate New York,
              Red Rock Ponds has something for everyone. You couldn’t ask for a better location. We’re just across
              the road from the Erie Canal where you can canoe the historic waters or walk/bike the shoreline on the
              Heritage Trail.
              <br/><br/>
              Our resort has a large recreation hall, well-stocked store, designated swim area with a sandy beach
              and activities for everyone – from Bingo and BBQ to food trucks and wine tasting. Plus, guests have
              access to paddle boats, rowboats, canoes and kayaks.
              <br/><br/>
              In the mood for a day trip? We’re only an hour’s drive from beaches, amusement parks, museums and
              shopping, and just 90 minutes from Niagara Falls. </p>
          </div>
        </div>
      </section>
      <section id="mature-woodland" className="section">
        <div className="container">
          <div className="columns is-mobile is-flex-mobile">
            <div className="column is-6 is-offset-6">
              <h4 className="title is-4">Mature Woodland</h4>
              <p className="has-text-weight-semibold">Designed and built with love; located in beautiful upstate New
                York woodland. Away from the hustle and bustle. We have nature trails and several small ponds. On
                your nature walk you may see deer, rabbits, squirrels and a variety of birds. Enjoy nature at its
                finest.</p>
            </div>
          </div>
        </div>
      </section>
      <section id="sandy-beach" className="section">
        <div className="container">
          <div className="columns is-mobile is-flex-mobile">
            <div className="column is-6">
              <h4 className="title is-4">Sandy Beach</h4>
              <p className="has-text-weight-semibold">Enjoy the sun, sand, and refreshing pond fed from a natural
                spring. While your children swim, you can relax under an umbrella. There are paddle boats and canoes
                available for your convenience.</p>
            </div>
          </div>
        </div>
      </section>
      <section id="fishing" className="section">
        <div className="container">
          <div className="columns is-mobile is-flex-mobile">
            <div className="column is-6 is-offset-6">
              <h4 className="title is-4">Fishing</h4>
              <p className="has-text-weight-semibold">You are in luck if fishing is your favorite pastime. Enjoy fishing
                in one of our many ponds. A fun day out with dads! All fishing is catch and release.</p>
            </div>
          </div>
        </div>
      </section>
      <section id="playground" className="section">
        <div className="container">
          <div className="columns is-mobile is-flex-mobile">
            <div className="column is-6">
              <h4 className="title is-4">Playground</h4>
              <p className="has-text-weight-semibold">There are two large play areas for children. We have a jungle gym,
                two swing sets, a basketball court, a volleyball court, a soccer field and much more.</p>
            </div>
          </div>
        </div>
      </section>
      <section id="nature-trails" className="section">
        <div className="container">
          <div className="columns is-mobile is-flex-mobile">
            <div className="column is-6 is-offset-6">
              <h4 className="title is-4">Nature Trails</h4>
              <p className="has-text-weight-semibold">Enjoy many scenic trails in the woods, around the ponds. Take
                a hike and take delight in all the fresh air.</p>
            </div>
          </div>
        </div>
      </section>
      <section id="full-hookup" className="section">
        <div className="container">
          <div className="columns is-mobile is-flex-mobile">
            <div className="column is-6">
              <h4 className="title is-4">Full Hookup</h4>
              <p className="has-text-weight-semibold">We took the time to lay underground utilities so you can enjoy
                nature as intended. All sites are full
                hookup with water, sewer, up to 50 amp electric, cable and WiFi. If you enjoy a campfire, every site has
                a
                fire pit. Campground has a shower building and two laundry facilities.</p>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default AmenityPage;
