import React from 'react';

import letchworthImg from "../images/attraction-page/letchworth_state_park.png"
import WatkinsGlenImg from "../images/attraction-page/watkins_glen.png"
import FingerLakesImg from "../images/attraction-page/finger_lakes.png"
import WineTourImg from "../images/attraction-page/wine_tour.png"
import CobblestoneMuseumImg from "../images/attraction-page/cobblestone_museum.png"
import LockportCaveImg from "../images/attraction-page/lockport_cave.png"
import MarineParkImg from "../images/attraction-page/marine_park.png"
import PointBreezeImg from "../images/attraction-page/point_breeze.png"
import SixFlagImg from "../images/attraction-page/six_flags.png"
import GeneseeVillageImg from "../images/attraction-page/genesee_village.png"
import GennaRationsFarmImg from "../images/attraction-page/genna_rations_farm.png"
import MedinaRailroadMuseumImg from "../images/attraction-page/medina_railroad_museum.png"

const AttractionPage = () => {
  return (
    <div id="attraction-page">
      <section className="hero is-medium is-primary is-bold">
        <div className="hero-body">
          <div className="container">
            <h1 className="title has-text-centered is-2">
              Discover Beautiful Upstate New York!
            </h1>
            <h2 className="subtitle has-text-centered has-text-weight-medium is-family-secondary">
              Untethered Nature Beauty.
            </h2>
          </div>
        </div>
      </section>
      <section className="section">
        <div className="container">
          <div className="content">
            <p className="has-text-centered">Discover beautiful upstate New York and everything it has to offer. Take a
              day trip to the stunning nature and amazing topography. Enjoy unique attractions and historic places.
              Explore the wine & brew trails and dine at delicious restaurants by the Finger Lakes.</p>
          </div>
          <div className="columns is-multiline">
            <div className="column is-4">
              <div className="card mb-3">
                <div className="card-image">
                  <figure className="image is-4by3">
                    <img src={letchworthImg} alt="Letchworth State Park"/>
                  </figure>
                </div>
                <div className="card-content">
                  <p className="title is-4">Letchworth State Park</p>
                  <div className="content">
                    Letchworth State Park, renowned as the &quot;Grand Canyon of the East,&quot; is one of the most scenically
                    magnificent areas in the eastern U.S. The Genesee River roars through the gorge over three major
                    waterfalls between cliffs--as high as 600 feet in some places--surrounded by lush forests.
                  </div>
                </div>
              </div>
              <div className="card mb-3">
                <div className="card-image">
                  <figure className="image is-4by3">
                    <img src={WineTourImg} alt="Wine Tour"/>
                  </figure>
                </div>
                <div className="card-content">
                  <p className="title is-4">Wine Tour</p>
                  <div className="content">
                    A beautiful countryside awaits visitors seeking an array of wineries and attractions rich with
                    history. Stretching across Niagara, Orleans and Monroe counties, the Niagara Wine Trail, USA is
                    minutes from Niagara Falls and just a short trip from the Buffalo and Rochester areas. There are
                    records of a winery that was open during the 19th century, and one of our current member wineries
                    produces fruit on vineyards that are over one hundred years old.
                  </div>
                </div>
              </div>
              <div className="card mb-3">
                <div className="card-image">
                  <figure className="image is-4by3">
                    <img src={MarineParkImg} alt="Orleans County Marine Park"/>
                  </figure>
                </div>
                <div className="card-content">
                  <p className="title is-4">Marine Park</p>
                  <div className="content">
                    11 acre public marine park on Oak Orchard River offering rental of 71 boat slips with electric and
                    water service, picnic pavilions with grills, fishing dock, INS videophone, showers and restrooms,
                    scenic overlook and summer events, including entertainment on Tuesday evenings during July and
                    August. Park open April 15 to November 1, 7:00 AM to 10:00 PM.
                  </div>
                </div>
              </div>
              <div className="card">
                <div className="card-image">
                  <figure className="image is-4by3">
                    <img src={GeneseeVillageImg} alt="Genesee Village"/>
                  </figure>
                </div>
                <div className="card-content">
                  <p className="title is-4">Genesee Village</p>
                  <div className="content">
                    There’s fun for every age at Genesee Country Village & Museum, New York State’s largest and most
                    comprehensive Living History Museum. Founded in 1966 by John L. Wehle, Genesee Country Village &
                    Museum was created to help preserve the vanishing rural architecture of the Genesee Country, an area
                    encompassing the Genesee River and stretching from the Finger Lakes to the Niagara Frontier and Lake
                    Erie. Today, this living history museum includes a 19th-century village, a gallery, a nature center
                    and 600 acres of gardens and wildlife.

                  </div>
                </div>
              </div>
            </div>
            <div className="column is-4">
              <div className="card mb-3">
                <div className="card-image">
                  <figure className="image is-4by3">
                    <img src={WatkinsGlenImg} alt="Watkins Glen"/>
                  </figure>
                </div>
                <div className="card-content">
                  <p className="title is-4">Watkins Glen</p>
                  <div className="content">
                    Watkins Glen State Park is the most famous of the Finger Lakes State Parks, with a reputation for
                    leaving visitors spellbound. Within two miles, the glen&apos;s stream descends 400 feet past 200-foot
                    cliffs, generating 19 waterfalls along its course. The gorge path winds over and under waterfalls
                    and through the spray of Cavern Cascade.
                  </div>
                </div>
              </div>
              <div className="card mb-3">
                <div className="card-image">
                  <figure className="image is-4by3">
                    <img src={CobblestoneMuseumImg} alt="Cobblestone Museum"/>
                  </figure>
                </div>
                <div className="card-content">
                  <p className="title is-4">Cobblestone Museum Society</p>
                  <div className="content">
                    For over 50 years, the Cobblestone Museum has served as the center for everything “cobblestone.”
                    Founded on the principles of historic preservation and education, the Museum has transformed into a
                    cultural center that promotes community engagement and the arts. Whether you are an artist, student,
                    aspiring historian, or simply someone who is passionate about their community, they have something
                    for you. The Cobblestone Museum is an open-air museum that promotes the study and exploration of
                    cobblestone construction methods from 1825 to 1860, offering visitors the opportunity to explore
                    three period cobblestone structures set in Victorian appearance and four wood structures
                    highlighting 19th century agricultural implements and skilled trades.
                  </div>
                </div>
              </div>
              <div className="card mb-3">
                <div className="card-image">
                  <figure className="image is-4by3">
                    <img src={PointBreezeImg} alt="Oak Orchard Lighthouse at Point Breeze"/>
                  </figure>
                </div>
                <div className="card-content">
                  <p className="title is-4">Point Breeze</p>
                  <div className="content">
                    Thousands have visited the Oak Orchard Lighthouse at Point Breeze, Oak Orchard Harbor on Lake
                    Ontario since the historic reconstruction was completed in 2010. The lighthouse is one of the jewels
                    in the Great Lakes Seaway Trail - the guest log has been signed by visitors from all around the
                    world, including New Zealand, Turkey, Germany, Alaska, Ireland, Canada, and all states in the union.
                  </div>
                </div>
              </div>
              <div className="card">
                <div className="card-image">
                  <figure className="image is-4by3">
                    <img src={GennaRationsFarmImg} alt="Genna Rations Farm"/>
                  </figure>
                </div>
                <div className="card-content">
                  <p className="title is-4">Genna Rations Farm</p>
                  <div className="content">
                    Genna-rations farm is a family-run farm. They have been growing their farm since November 2003,
                    raising alpacas for breeding stock and fiber, hand-poured 100% natural soy candles, and grow their
                    well-known garlic. They welcome visitors year round. Their store is open anytime by appointment.
                  </div>
                </div>
              </div>
            </div>
            <div className="column is-4">
              <div className="card mb-3">
                <div className="card-image">
                  <figure className="image is-4by3">
                    <img src={FingerLakesImg} alt="Finger Lakes"/>
                  </figure>
                </div>
                <div className="card-content">
                  <p className="title is-4">Finger Lakes</p>
                  <div className="content">
                    Deep clear lakes, spectacular gorges, abundant waterfalls and fishing and boating opportunities
                    galore- this region will leave you breathless.
                  </div>
                </div>
              </div>
              <div className="card mb-3">
                <div className="card-image">
                  <figure className="image is-4by3">
                    <img src={LockportCaveImg} alt="The Lockport Cave"/>
                  </figure>
                </div>
                <div className="card-content">
                  <p className="title is-4">The Lockport Cave</p>
                  <div className="content">
                    Experience an underground boat ride that is both Unique and Mysterious: The next part of your
                    journey is a one-of-a-kind underground boat ride. Embark on one of America’s longest underground
                    boat rides along the Erie Canal and experience a trip of wonders. Marvel at artifacts left by miners
                    on the Erie Canal over a century ago. View cave formations in their early stages of development.
                    Visitors are awestruck as they take a ride that has been described as both “peaceful and eerie” as
                    the boat glides through the lifeless water, illuminated only by small, sporadically placed electric
                    lights.
                  </div>
                </div>
              </div>
              <div className="card mb-3">
                <div className="card-image">
                  <figure className="image is-4by3">
                    <img src={SixFlagImg} alt="Six Flags - Darien Lake"/>
                  </figure>
                </div>
                <div className="card-content">
                  <p className="title is-4">Six Flags</p>
                  <div className="content">
                    Darien Lake is New York’s largest water & theme park with rides, thrills and coasters the whole
                    family will adore. Darien Lake was rebranded Six Flags Darien Lake when it opened for the 2019
                    season. The Coaster Capital of New York also debuted an exhilarating, new attraction — Six Flags
                    SkyScreamer — the tallest attraction in the state of New York. Towering high above the park at a
                    staggering 242 feet in height, this thrilling swing ride will reign supreme as the region’s new,
                    iconic symbol, beckoning guests of all ages from near and far.
                  </div>
                </div>
              </div>
              <div className="card">
                <div className="card-image">
                  <figure className="image is-4by3">
                    <img src={MedinaRailroadMuseumImg} alt="Medina Railroad Museum"/>
                  </figure>
                </div>
                <div className="card-content">
                  <p className="title is-4">Railroad Museum</p>
                  <div className="content">
                    Your Train Ticket allows entry into our huge Train Museum. Their museum is filled with railroad
                    artifacts, has one of the biggest model-train layouts in the country, and features fun train
                    excursions all year round.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>

  );
};

export default AttractionPage;
