import React from 'react';

const Footer = () => {
  return (
    <div>
      <footer className="footer">
        <div className="container is-fluid">
          <div className="columns">
            <div className="column is-4">
              <h5 className="title is-5">Contact</h5>
              <dl className="no-style">
                <li><strong>Address:</strong><span
                  className="is-pulled-right">16097 Canal Rd.,<br/>Holley, NY 14470</span></li>
                <li>&nbsp;</li>
                <li><strong>Phone:</strong><span className="is-pulled-right"><a href="tel:585-638-2445">(585) 638-2445</a></span></li>
                <li><strong>Email:</strong><span className="is-pulled-right"><a href="mailto:contactus@redrockponds.com">contactus@redrockponds.com</a></span></li>
              </dl>
            </div>
            <div className="column is-4">
              <h5 className="title is-5">Hours</h5>
              <dl className="no-style">
                <li><strong>Sun-Thu</strong><span className="is-pulled-right">10 AM - 4 PM</span></li>
                <li><strong>Fri</strong><span className="is-pulled-right">10 AM - 7 PM</span></li>
                <li><strong>Sat</strong><span className="is-pulled-right">10 AM - 6 PM</span></li>
              </dl>
            </div>
            <div className="column is-4">
              <h5 className="title is-5">Social Media</h5>
              <dl className="no-style">
                <li><strong><i className="fa fa-facebook"/> Facebook</strong><span className="is-pulled-right"><a href="https://www.facebook.com/RedRockPonds">Visit us on Facebook</a></span></li>
                <li><strong><i className="fa fa-instagram"/> Instagram</strong><span className="is-pulled-right"><a href="https://www.instagram.com/redrockponds/">Connect on Instagram</a></span></li>
                <li><strong><i className="fa fa-twitter"/> Twitter</strong><span className="is-pulled-right"><a href="https://twitter.com/redrockponds">Tweet at us on Twitter</a></span></li>
              </dl>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default Footer;
