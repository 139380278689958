import * as types from '../constants/actionTypes';

export function setReservationParameter(name, value) {
  return function (dispatch) {
    return dispatch({
      type: types.SET_RESERVATION_PARAMETER,
      name,
      value
    });
  };
}

export function setReservationResponse(response) {
  return function (dispatch) {
    return dispatch({
      type: types.SET_RESERVATION_RESPONSE,
      response
    });
  };
}

export function validateReservation() {
  return function (dispatch) {
    return dispatch({
      type: types.VALIDATE_RESERVATION
    });
  };
}

export function submitReservation() {
  let arrivalDate, departureDate;
  return function (dispatch, getState) {
    dispatch(setReservationResponse({type:"", response:""}));
    dispatch(validateReservation());
    if (getState().reservationParameters.validation.valid) {
      arrivalDate = getState().reservationParameters.fields.arrivalDate.value;
      departureDate = getState().reservationParameters.fields.departureDate.value;
      dispatch(setReservationResponse({type:"url", message:"/".concat(
        new Date(arrivalDate.getTime() - (arrivalDate.getTimezoneOffset() * 60000)).toISOString().split('T')[0],"/",
        new Date(departureDate.getTime() - (departureDate.getTimezoneOffset() * 60000)).toISOString().split('T')[0],"/guests",
          getState().reservationParameters.fields.numOfChildren.value,",",
          getState().reservationParameters.fields.numOfAdults.value,",",
          getState().reservationParameters.fields.numOfPets.value,"/list",
        )}));
    }
  };
}
