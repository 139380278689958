export const SET_SITE_PARAMETERS = "SET_SITE_PARAMETERS";

export const SET_RESERVATION_PARAMETER = "SET_RESERVATION_PARAMETER";
export const SET_RESERVATION_RESPONSE = "SET_RESERVATION_RESPONSE";
export const VALIDATE_RESERVATION = "VALIDATE_RESERVATION";

export const SET_SEASONAL_PARAMETER = "SET_SEASONAL_PARAMETER";
export const SET_SEASONAL_RESPONSE = "SET_SEASONAL_RESPONSE";
export const VALIDATE_SEASONAL = "VALIDATE_SEASONAL";

export const SET_CONTACT_PARAMETER = "SET_CONTACT_PARAMETER";
export const SET_CONTACT_RESPONSE = "SET_CONTACT_RESPONSE";
export const VALIDATE_CONTACT = "VALIDATE_CONTACT";

export const TOGGLE_NAV_MENU = "TOGGLE_NAV_MENU";
export const CLOSE_NAV_MENU = "CLOSE_NAV_MENU";
