import {SET_SEASONAL_PARAMETER, SET_SEASONAL_RESPONSE, VALIDATE_SEASONAL} from '../constants/actionTypes';
import objectAssign from 'object-assign';
import initialState from './initialState';

function validateField(field, newValue = null) {
  let error = false;
  let testValue = newValue === null ? field.value : newValue
  // Validate each field
  /*switch (key) {
    case "firstName":
      value = action.value.match(/[a-zA-Z\s]+/gm)[0];
  }*/
  error = (!testValue || /^\s*$/.test(testValue)) && field.isRequired;
  field = objectAssign({}, field, {
    ...field,
    value: testValue,
    error: error,
    message: "This field is required."
  })
  return field;
}

export default function seasonalReducer(state = initialState.seasonalParameters, action) {
  let newState;
  let error;
  switch (action.type) {
    case SET_SEASONAL_PARAMETER:
      return objectAssign({}, state, {
        fields: {
          ...state.fields,
          [action.name]: validateField(state.fields[action.name], action.value)
        }
      })

    case SET_SEASONAL_RESPONSE:
      return objectAssign({}, state, {
        response: action.response
      })

    case VALIDATE_SEASONAL:
      newState = objectAssign({}, state);
      error = false;
      Object.keys(newState.fields).forEach(key => {
        newState = objectAssign({}, newState, {
          fields: {
            ...newState.fields,
            [key]: validateField(newState.fields[key])
          }
        })
        error = error || newState.fields[key].error
      });
      if (error) {
        newState["validation"] = {valid: false, message: "Please review and complete all required fields."}
      } else {
        newState["validation"] = {valid: true, message: ""}
      }
      return newState;

    default:
      return state;
  }
}
