import Announcement from "./partial/Announcement"

import React from 'react';
import PropTypes from "prop-types";
import Slider from "react-slick";
import {ParallaxBanner, ParallaxProvider} from 'react-scroll-parallax';
import {Link} from "react-router-dom"

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

// import logoWhite from "../images/common/logo_white.png"
import logoColor from "../images/common/logo_color.png"
import beachImg from "../images/home-page/beach.png"
import woodlandImg from "../images/home-page/woodland.png"
import fishingImg from "../images/home-page/fishing.png"
import playgroundImg from "../images/home-page/playground.png"
import trailsImg from "../images/home-page/trails.png"
import fhuImg from "../images/home-page/fhu.png"
import bonfireImg from "../images/seasonal-page/fun_1.jpg"
import partyImg from "../images/seasonal-page/fun_2.jpg"
import halloweenImg from "../images/seasonal-page/fun_3.jpg"
import campingBgImg from "../images/home-page/activities_bg.png"
import campingFg1Img from "../images/home-page/activities_fg.png"
import campingFg2Img from "../images/home-page/activities_fg_1.png"
import campingFg3Img from "../images/home-page/activities_fg_2.png"

class HomePage extends React.Component {
  render() {
    return (
      <div id="home-page">
        <HomeHeroSlider/>
        <Announcement/>
        <section id="highlights" className="section">
          <div className="container">
            <div id="intro">
              <h3 className="title has-text-centered section-title is-3">A Tranquil Getaway on the Erie Canal</h3>
              <h4 className="subtitle is-4 has-text-centered">Your summer hideout.</h4>
              <div>
                <p className="has-text-justified has-text-weight-medium">Whether it’s bass fishing in a tranquil pond,
                  relaxing by the fire under a canopy of hardwoods, or exploring the rich history of upstate New York,
                  Red Rock Ponds has something for everyone. You couldn’t ask for a better location. We’re just across
                  the road from the Erie Canal where you can canoe the historic waters or walk/bike the shoreline on the
                  Heritage Trail.
                  <br/><br/>
                  Our resort has a large recreation hall, well-stocked store, designated swim area with a sandy beach
                  and activities for everyone – from Bingo and BBQ to food trucks and wine tasting. Plus, guests have
                  access to paddle boats, rowboats, canoes and kayaks.
                  <br/><br/>
                  In the mood for a day trip? We’re only an hour’s drive from beaches, amusement parks, museums and
                  shopping, and just 90 minutes from Niagara Falls. </p>
              </div>
            </div>
            <div className="columns is-multiline is-variable is-8-desktop">
              <div className="column is-4 has-text-centered">
                <figure className="image">
                  <img src={woodlandImg} className="is-rounded" alt="Mature Woodland"/>
                </figure>
                <div>
                  <h4 className="title is-4">Mature Woodland</h4>
                  <p className="has-text-weight-semibold">Designed and built with love; located in beautiful upstate New
                    York woodland. Away from the hustle and bustle. Enjoy nature at its finest.</p>
                </div>
              </div>
              <div className="column is-4 has-text-centered">
                <figure className="image">
                  <img src={beachImg} className="is-rounded" alt="Sandy Beach"/>
                </figure>
                <div>
                  <h4 className="title is-4">Sandy Beach</h4>
                  <p className="has-text-weight-semibold">Enjoy the sun, sand, and refreshing pond fed from a natural
                    spring. While your children swim, you can relax under an umbrella. Boats and canoes available.</p>
                </div>
              </div>
              <div className="column is-4 has-text-centered">
                <figure className="image">
                  <img src={fishingImg} className="is-rounded" alt="Fishing"/>
                </figure>
                <div className="content">
                  <h4 className="title is-4">Fishing</h4>
                  <p className="has-text-weight-semibold">You are in luck if fishing is your favorite pastime. Enjoy
                    fishing
                    in one of our many ponds. A fun day out with dads! All fishing is catch and release.</p>
                </div>
              </div>
              <div className="column is-4 has-text-centered">
                <figure className="image">
                  <img src={playgroundImg} className="is-rounded" alt="Playground"/>
                </figure>
                <div className="content">
                  <h4 className="title is-4">Playground</h4>
                  <p className="has-text-weight-semibold">There are two large play areas for children. We have a jungle
                    gym,
                    two swing sets, a basketball court, a volleyball court, a soccer field and much more.</p>
                </div>
              </div>
              <div className="column is-4 has-text-centered">
                <figure className="image">
                  <img src={trailsImg} className="is-rounded" alt="Nature Trails"/>
                </figure>
                <div className="content">
                  <h4 className="title is-4">Nature Trails</h4>
                  <p className="has-text-weight-semibold">Enjoy many scenic trails in the woods, around the ponds. Take
                    a hike and take delight in all the fresh air.</p>
                </div>
              </div>
              <div className="column is-4 has-text-centered">
                <figure className="image">
                  <img src={fhuImg} className="is-rounded" alt="Full Hookup"/>
                </figure>
                <div className="content">
                  <h4 className="title is-4">Full Hookup</h4>
                  <p className="has-text-weight-semibold">We took the time to lay underground utilities so you can
                    enjoy nature as intended. All sites are full hookup with Water, sewer, up to 50A electric, cable and
                    WiFi.</p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section id="activities-general" className="section is-paddingless">
          <ParallaxProvider>
            <div className="vertical">
              <div>
                <ParallaxBanner
                  layers={[
                    {
                      image:
                      campingBgImg,
                      amount: 0.5,
                    },
                    {
                      image:
                      campingFg1Img,
                      amount: 0.4,
                    },
                    {
                      image:
                      campingFg2Img,
                      amount: 0.25,
                    },
                    {
                      image:
                      campingFg3Img,
                      amount: 0.15,
                    }
                  ]}
                  style={{height: '50vh'}}
                />
              </div>
            </div>
          </ParallaxProvider>
        </section>
        <section id="activities-details" className="section has-background-grey-light">
          <div className="container">
            <h3 className="title has-text-centered section-title is-3">A Whole Lot of Fun!</h3>
            <h4 className="subtitle is-4 has-text-centered">Enjoy your solitude or join us for events throughout the
              year.</h4>
            <div>
              <p className="has-text-justified has-text-weight-medium">Whether you are a couple, a family, or enjoying
                your golden years. We got something for everyone. Enjoy a cozy camp fire with close friends and family,
                the great outdoors and the beautiful nature. Or join us in exciting events, old staples and new; Tiki
                island bonfire on the beach. Annual Red Rock Halloween. And live music just to name a few. Fall in love
                with the community, the atmosphere, delicious food and everything in between. Full list of events and
                activities can be found <Link to="/activity">here.</Link></p>
            </div>
            <br/>
            <div className="columns is-multiline is-variable is-6-desktop">
              <div className="column is-4">
                <div className="card">
                  <div className="card-image">
                    <figure className="image is-4by3">
                      <img src={bonfireImg} alt="Bonfire at the beach"/>
                    </figure>
                  </div>
                  <div className="card-content">
                    <p className="title is-4">Themed Weekends</p>
                    <div className="content">
                      Join us for a magical night under the stars. Cozy up in our annual Tiki island beach bonfire
                      in an intimate setting. Dance to the timeless island music and indulge a yummy island drink, or
                      two. A good time with great company.
                    </div>
                  </div>
                </div>
              </div>
              <div className="column is-4">
                <div className="card">
                  <div className="card-image">
                    <figure className="image is-4by3">
                      <img src={partyImg} alt="Live music"/>
                    </figure>
                  </div>
                  <div className="card-content">
                    <p className="title is-4">Get Togethers</p>
                    <div className="content">
                      Meet new people and hangout with camp friends. We host numerous themed parties and get togethers
                      through out the season. Potlucks, pizza party, wine party, pig roast, chicken BBQ, icecream socials and more!
                    </div>
                  </div>
                </div>
              </div>
              <div className="column is-4">
                <div className="card">
                  <div className="card-image">
                    <figure className="image is-4by3">
                      <img src={halloweenImg} alt="Red Rock Halloween"/>
                    </figure>
                  </div>
                  <div className="card-content">
                    <p className="title is-4">Special Events</p>
                    <div className="content">
                      Love Halloween? Get in your best costumes and trick or treat through the park. Bouncy house all
                      weekend! See the wonderful light show our seasonal campers has put up on a wagon ride after dark.
                      Walk haunted lake trails and enjoy some hot cider and donut.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section id="shortcut" className="section">
          <div className="container">
            <div className="columns">
              <div className="column is-4">
                <p className=" has-text-centered has-text-weight-semibold">Ready to make an reservation?</p><br/>
                <Link to="/reservation" className="no-decoration has-text-white">
                  <button className="button is-success">
                  <span className="icon">
                    <i className="fa fa-book"></i>
                  </span>
                    <span>Book Now</span>
                  </button>
                </Link>
              </div>
              <div className="column is-4">
                <p className=" has-text-centered has-text-weight-semibold">Like a seasonal spot?</p><br/>
                <Link to="/seasonal#apply" className="no-decoration has-text-white">
                  <button className="button is-success">
                  <span className="icon">
                    <i className="fa fa-users"></i>
                  </span>
                    <span>Join Now</span>
                  </button>
                </Link>
              </div>
              <div className="column is-4">
                <p className=" has-text-centered has-text-weight-semibold">Need more information?</p><br/>
                <Link to="/contact" className="no-decoration has-text-white">
                  <button className="button is-success">
                  <span className="icon">
                    <i className="fa fa-comments"></i>
                  </span>
                    <span>Contact Us</span>
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

class HeroSlide extends React.Component {
  render() {
    const {id, title, sub, ...props} = this.props;
    return (
      <div id={id} className="hero is-medium is-success" {...props}>
        <div className="hero-body">
          <div className="container">
            <figure className="image container is-128x128">
              <img src={logoColor} alt="White Red Rock Ponds Logo"/>
            </figure>
            <br/>
            <h1 className="title is-2 has-text-centered">
              {title}
            </h1>
            <h5 className="subtitle is-5 has-text-centered has-text-weight-medium is-family-secondary">
              {sub}
            </h5>
          </div>
        </div>
      </div>
    );
  }
}

class HomeHeroSlider extends React.Component {
  render() {
    const settings = {
      autoplay: true,
      autoplaySpeed: 5000,
      arrows: false,
      dots: false,
      lazyLoad: true,
      infinite: true,
      speed: 1000,
      slidesToShow: 1,
      slidesToScroll: 1
    };
    return (
      <Slider {...settings}>
        <HeroSlide id="slider-1" title="Welcome to Red Rock Ponds"
                   sub="Your premiere destination on the Erie Canal!"/>
        <HeroSlide id="slider-2" title="Sit Back, and Relax!"
                   sub="Sunny day, warm sand and refreshing quarry water."/>
        <HeroSlide id="slider-3" title="Fun for the Whole Family"
                   sub="Join us for our many planned events, fun activities for everyone!"/>
      </Slider>
    );
  }
}

HeroSlide.propTypes = {
  id: PropTypes.string,
  pre: PropTypes.string,
  title: PropTypes.string,
  sub: PropTypes.string
};

export default HomePage;
