import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {Link} from "react-router-dom"
import ReactGA from 'react-ga'
import ReactPixel from 'react-facebook-pixel';
import * as actions from '../actions/seasonalActions';
import {seasonalParameters} from '../types';

import community1Img from "../images/seasonal-page/community_1.jpg"
import community2Img from "../images/seasonal-page/community_2.jpg"
import community3Img from "../images/seasonal-page/community_3.jpg"
import park1Img from "../images/seasonal-page/park_1.jpg"
import park2Img from "../images/seasonal-page/park_2.jpg"
import park3Img from "../images/seasonal-page/park_3.jpg"
import fun1Img from "../images/seasonal-page/fun_1.jpg"
import fun2Img from "../images/seasonal-page/fun_2.jpg"
import fun3Img from "../images/seasonal-page/fun_3.jpg"


export class SeasonalPage extends React.Component {
  constructor(props) {
    super(props)
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange = (ev) => {
    this.props.actions.setSeasonalParameter(ev.target.name, ev.target.value);
  }

  handleSubmit = () => {
    ReactGA.event({
      category: "Submit Seasonal Application",
      action: "User Tried to Submit Seasonal Application.",
    });
    ReactPixel.track( "Lead", {
      value: "Seasonal",
    } )
    this.props.actions.submitSeasonal();
  }

  render() {
    return (
      <div id="seasonal-page">
        <section className="hero is-medium is-primary is-bold">
          <div className="hero-body">
            <div className="container">
              <h1 className="title has-text-centered is-2">
                Join the Red Rock Ponds Family!
              </h1>
              <h2 className="subtitle has-text-centered has-text-weight-medium is-family-secondary">
                Amazing community. Gorgeous park and great Fun!
              </h2>
            </div>
          </div>
        </section>
        <section id="community" className="section">
          <div className="container">
            <div>
              <h3 className="title has-text-centered section-title is-3">Community</h3>
              <h4 className="subtitle has-text-centered">Become part of our camping family.</h4>
              <div>
                <p>Tired of packing, unpacking and hauling your RV everywhere? Join us at Red Rock Ponds where your
                  personal piece of paradise is ready and waiting. From neighbors to camp buddies, to life time friends,
                  our welcoming camping family will accept you with open arms.</p>
              </div>
            </div><br/>
            <div className="columns is-multiline is-variable is-6-desktop">
              <div className="column is-4">
                <div className="card">
                  <div className="card-image">
                    <figure className="image is-4by3">
                      <img src={community1Img} alt="picnic with friends"/>
                    </figure>
                  </div>
                  <div className="card-content">
                    <p className="title is-4">Make Friends</p>
                    <div className="content">
                      Strike up a conversion with friendly fellow campers. Make life longs friends!
                    </div>
                  </div>
                </div>
              </div>
              <div className="column is-4">
                <div className="card">
                  <div className="card-image">
                    <figure className="image is-4by3">
                      <img src={community2Img} alt="campfire get together"/>
                    </figure>
                  </div>
                  <div className="card-content">
                    <p className="title is-4">Enjoy a Campfire</p>
                    <div className="content">
                      Grab a chair and join a campfire! Relax and get cozy. All is welcome!
                    </div>
                  </div>
                </div>
              </div>
              <div className="column is-4">
                <div className="card">
                  <div className="card-image">
                    <figure className="image is-4by3">
                      <img src={community3Img} alt="clubhouse event"/>
                    </figure>
                  </div>
                  <div className="card-content">
                    <p className="title is-4">Join Our Community</p>
                    <div className="content">
                      Where everyone knows your name. And we&apos;re always glad you came!
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section id="park" className="section">
          <div className="container">
            <div>
              <h3 className="title has-text-centered section-title is-3">Gorgeous Park</h3>
              <h4 className="subtitle has-text-centered">Professionally designed and built with love; Red Rock Ponds
                is a serene paradise like none other.</h4>
              <div>
                <p>Spacious sites in a woodland setting; many of our sites are directly back to the woods with lots of
                  mature trees for shading. With new trees planted every year, the park becomes ever more gorgeous every
                  passing season. Meticulously maintained and always improving! We are always looking for ways to make
                  the park even better. Take a drive and come and see for yourself!</p>
              </div>
            </div><br/>
            <div className="columns is-multiline is-variable is-6-desktop">
              <div className="column is-4">
                <div className="card">
                  <div className="card-image">
                    <figure className="image is-4by3">
                      <img src={park1Img} alt="beautiful site"/>
                    </figure>
                  </div>
                  <div className="card-content">
                    <p className="title is-4">Simply Picturesque</p>
                    <div className="content">
                      Take a strode and enjoy the beautiful landscape, night-scape and wonderful curated gardens!
                    </div>
                  </div>
                </div>
              </div>
              <div className="column is-4">
                <div className="card">
                  <div className="card-image">
                    <figure className="image is-4by3">
                      <img src={park2Img} alt="beautiful pond"/>
                    </figure>
                  </div>
                  <div className="card-content">
                    <p className="title is-4">Beautifully Kept</p>
                    <div className="content">
                      We take pride in providing a scenic and pleasant experience for your enjoyment!
                    </div>
                  </div>
                </div>
              </div>
              <div className="column is-4">
                <div className="card">
                  <div className="card-image">
                    <figure className="image is-4by3">
                      <img src={park3Img} alt="beautiful rv set up"/>
                    </figure>
                  </div>
                  <div className="card-content">
                    <p className="title is-4">Make It Your Own</p>
                    <div className="content">
                      Build your own paradise, make your campsite your own. Make yourself comfortable!
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section id="fun" className="section">
          <div className="container">
            <div>
              <h3 className="title has-text-centered section-title is-3">Great Fun! </h3>
              <h4 className="subtitle has-text-centered">We have a large recreation building that can handle many
                different activities. We offer a variety of craft activities, game nights, and other special get
                togethers. See our full list of activities <Link to="/activity">here.</Link></h4>
              <br/>
            </div>
            <div className="columns is-multiline is-variable is-6-desktop">
              <div className="column is-4">
                <div className="card">
                  <div className="card-image">
                    <figure className="image is-4by3">
                      <img src={fun1Img} alt="Bonfire at the beach"/>
                    </figure>
                  </div>
                  <div className="card-content">
                    <p className="title is-4">Themed Weekends</p>
                    <div className="content">
                      Join us for a magical night under the stars. Cozy up in our annual Tiki island beach bonfire
                      in an intimate setting. Dance to the timeless island music and indulge a yummy island drink, or
                      two. A good time with great company.
                    </div>
                  </div>
                </div>
              </div>
              <div className="column is-4">
                <div className="card">
                  <div className="card-image">
                    <figure className="image is-4by3">
                      <img src={fun2Img} alt="Live music"/>
                    </figure>
                  </div>
                  <div className="card-content">
                    <p className="title is-4">Get Togethers</p>
                    <div className="content">
                      Meet new people and hangout with camp friends. We host numerous themed parties and get togethers
                      through out the season. Potlucks, pizza party, wine party, pig roast, chicken BBQ, icecream
                      socials and more!
                    </div>
                  </div>
                </div>
              </div>
              <div className="column is-4">
                <div className="card">
                  <div className="card-image">
                    <figure className="image is-4by3">
                      <img src={fun3Img} alt="Red Rock Halloween"/>
                    </figure>
                  </div>
                  <div className="card-content">
                    <p className="title is-4">Special Events</p>
                    <div className="content">
                      Love Halloween? Get in your best costumes and trick or treat through the park. Bouncy house all
                      weekend! See the wonderful light show our seasonal campers has put up on a wagon ride after dark.
                      Walk haunted lake trails and enjoy some hot cider and donut.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section id="apply" className="section">
          <div className="container">
            <div>
              <h3 className="title has-text-centered section-title is-3 has-text-white">Apply Now</h3>
              <h4 className="subtitle has-text-centered has-text-white">Fill out the form below to get started. Fields
                with red asterisk are required.</h4>
              <div className="columns is-multiline">
                <div className="column is-6">
                  <div className="field">
                    <label className="label has-text-white">First Name<strong
                      className="has-text-danger">*</strong></label>
                    <div className="control has-icons-right">
                      <input
                        className={`input ${this.props.seasonalParameters.fields.firstName.error ? "is-danger" : ""}`}
                        type="text" placeholder="First Name" name="firstName"
                        value={this.props.seasonalParameters.fields.firstName.value} onChange={this.handleChange}/>
                      <span className="icon is-small is-right">
                      <i
                        className={`fa fa-exclamation-triangle has-text-danger ${this.props.seasonalParameters.fields.firstName.error ? "" : "is-invisible"}`}/>
                    </span>
                    </div>
                    <p
                      className={`help is-danger has-text-danger ${this.props.seasonalParameters.fields.firstName.error ? "" : "is-hidden"}`}>{this.props.seasonalParameters.fields.firstName.message}</p>
                  </div>
                </div>
                <div className="column is-6">
                  <div className="field">
                    <label className="label has-text-white">Last Name<strong
                      className="has-text-danger">*</strong></label>
                    <div className="control has-icons-right">
                      <input
                        className={`input ${this.props.seasonalParameters.fields.lastName.error ? "is-danger" : ""}`}
                        type="text" placeholder="Last Name" name="lastName"
                        value={this.props.seasonalParameters.fields.lastName.value} onChange={this.handleChange}/>
                      <span className="icon is-small is-right">
                      <i
                        className={`fa fa-exclamation-triangle has-text-danger ${this.props.seasonalParameters.fields.lastName.error ? "" : "is-invisible"}`}/>
                    </span>
                    </div>
                    <p
                      className={`help is-danger has-text-danger ${this.props.seasonalParameters.fields.lastName.error ? "" : "is-hidden"}`}>{this.props.seasonalParameters.fields.lastName.message}</p>
                  </div>
                </div>
                <div className="column is-6">
                  <div className="field">
                    <label className="label has-text-white">Phone<strong className="has-text-danger">*</strong></label>
                    <div className="control has-icons-left has-icons-right">
                      <input
                        className={`input ${this.props.seasonalParameters.fields.phone.error ? "is-danger" : ""}`}
                        type="tel" placeholder="Phone" name="phone"
                        value={this.props.seasonalParameters.fields.phone.value} onChange={this.handleChange}/>
                      <span className="icon is-small is-left">
                      <i className="fa fa-phone"></i>
                    </span>
                      <span className="icon is-small is-right">
                      <i
                        className={`fa fa-exclamation-triangle has-text-danger ${this.props.seasonalParameters.fields.phone.error ? "" : "is-invisible"}`}/>
                    </span>
                    </div>
                    <p
                      className={`help is-danger has-text-danger ${this.props.seasonalParameters.fields.phone.error ? "" : "is-hidden"}`}>{this.props.seasonalParameters.fields.phone.message}</p>
                  </div>
                </div>
                <div className="column is-6">
                  <div className="field">
                    <label className="label has-text-white">Email<strong className="has-text-danger">*</strong></label>
                    <div className="control has-icons-left has-icons-right">
                      <input
                        className={`input ${this.props.seasonalParameters.fields.email.error ? "is-danger" : ""}`}
                        type="email" placeholder="Email" name="email"
                        value={this.props.seasonalParameters.fields.email.value} onChange={this.handleChange}/>
                      <span className="icon is-small is-left">
                      <i className="fa fa-envelope"></i>
                    </span>
                      <span className="icon is-small is-right">
                      <i
                        className={`fa fa-exclamation-triangle has-text-danger ${this.props.seasonalParameters.fields.email.error ? "" : "is-invisible"}`}/>
                    </span>
                    </div>
                    <p
                      className={`help is-danger has-text-danger ${this.props.seasonalParameters.fields.email.error ? "" : "is-hidden"}`}>{this.props.seasonalParameters.fields.email.message}</p>
                  </div>
                </div>
              </div>
              <hr className="hr"/>
              <div className="columns is-multiline">
                <div className="column is-12">
                  <div className="field">
                    <label className="label has-text-white">Address<strong
                      className="has-text-danger">*</strong></label>
                    <div className="control has-icons-left has-icons-right">
                      <input
                        className={`input ${this.props.seasonalParameters.fields.address.error ? "is-danger" : ""}`}
                        type="text" placeholder="Street" name="address"
                        value={this.props.seasonalParameters.fields.address.value}
                        onChange={this.handleChange}/>
                      <span className="icon is-small is-left">
                      <i className="fa fa-map"></i>
                      </span>
                      <span className="icon is-small is-right">
                        <i
                          className={`fa fa-exclamation-triangle has-text-danger ${this.props.seasonalParameters.fields.address.error ? "" : "is-invisible"}`}/>
                      </span>
                    </div>
                    <p
                      className={`help is-danger has-text-danger ${this.props.seasonalParameters.fields.address.error ? "" : "is-hidden"}`}>{this.props.seasonalParameters.fields.address.message}</p>
                  </div>
                </div>
                <div className="column is-4">
                  <div className="field">
                    <label className="label has-text-white">City<strong className="has-text-danger">*</strong></label>
                    <div className="control has-icons-right">
                      <input className={`input ${this.props.seasonalParameters.fields.city.error ? "is-danger" : ""}`}
                             type="text" placeholder="City" name="city"
                             value={this.props.seasonalParameters.fields.city.value} onChange={this.handleChange}/>
                      <span className="icon is-small is-right">
                        <i
                          className={`fa fa-exclamation-triangle has-text-danger ${this.props.seasonalParameters.fields.city.error ? "" : "is-invisible"}`}/>
                      </span>
                    </div>
                    <p
                      className={`help is-danger has-text-danger ${this.props.seasonalParameters.fields.city.error ? "" : "is-hidden"}`}>{this.props.seasonalParameters.fields.city.message}</p>
                  </div>
                </div>
                <div className="column is-4">
                  <div className="field">
                    <label className="label has-text-white">State/Province<strong className="has-text-danger">*</strong></label>
                    <div className="control is-expanded">
                      <div className="select is-fullwidth">
                        <select
                          className={`input ${this.props.seasonalParameters.fields.state.error ? "is-danger" : ""}`}
                          name="state" value={this.props.seasonalParameters.fields.state.value}
                          onChange={this.handleChange}>
                          <option value="">&nbsp;&nbsp;&nbsp;</option>
                          <option value="--">–</option>
                          <option value="AA">AA</option>
                          <option value="AE">AE</option>
                          <option value="AK">AK</option>
                          <option value="AL">AL</option>
                          <option value="AP">AP</option>
                          <option value="AR">AR</option>
                          <option value="AS">AS</option>
                          <option value="AZ">AZ</option>
                          <option value="CA">CA</option>
                          <option value="CO">CO</option>
                          <option value="CT">CT</option>
                          <option value="DC">DC</option>
                          <option value="DE">DE</option>
                          <option value="FL">FL</option>
                          <option value="FM">FM</option>
                          <option value="GA">GA</option>
                          <option value="GU">GU</option>
                          <option value="HI">HI</option>
                          <option value="IA">IA</option>
                          <option value="ID">ID</option>
                          <option value="IL">IL</option>
                          <option value="IN">IN</option>
                          <option value="KS">KS</option>
                          <option value="KY">KY</option>
                          <option value="LA">LA</option>
                          <option value="MA">MA</option>
                          <option value="MD">MD</option>
                          <option value="ME">ME</option>
                          <option value="MH">MH</option>
                          <option value="MI">MI</option>
                          <option value="MN">MN</option>
                          <option value="MO">MO</option>
                          <option value="MP">MP</option>
                          <option value="MS">MS</option>
                          <option value="MT">MT</option>
                          <option value="NC">NC</option>
                          <option value="ND">ND</option>
                          <option value="NE">NE</option>
                          <option value="NH">NH</option>
                          <option value="NJ">NJ</option>
                          <option value="NM">NM</option>
                          <option value="NV">NV</option>
                          <option value="NY">NY</option>
                          <option value="OH">OH</option>
                          <option value="OK">OK</option>
                          <option value="OR">OR</option>
                          <option value="PA">PA</option>
                          <option value="PR">PR</option>
                          <option value="PW">PW</option>
                          <option value="RI">RI</option>
                          <option value="SC">SC</option>
                          <option value="SD">SD</option>
                          <option value="TN">TN</option>
                          <option value="TX">TX</option>
                          <option value="UT">UT</option>
                          <option value="VA">VA</option>
                          <option value="VI">VI</option>
                          <option value="VT">VT</option>
                          <option value="WA">WA</option>
                          <option value="WI">WI</option>
                          <option value="WV">WV</option>
                          <option value="WY">WY</option>
                          <option value="">--</option>
                          <option value="AB">AB</option>
                          <option value="BC">BC</option>
                          <option value="LB">LB</option>
                          <option value="MB">MB</option>
                          <option value="NB">NB</option>
                          <option value="NF">NF</option>
                          <option value="NT">NT</option>
                          <option value="NS">NS</option>
                          <option value="ON">ON</option>
                          <option value="PE">PE</option>
                          <option value="QC">QC</option>
                          <option value="SK">SK</option>
                          <option value="YT">YT</option>
                        </select>
                      </div>
                      <p
                        className={`help is-danger has-text-danger ${this.props.seasonalParameters.fields.state.error ? "" : "is-hidden"}`}>{this.props.seasonalParameters.fields.state.message}</p>
                    </div>
                  </div>
                </div>
                <div className="column is-4">
                  <div className="field">
                    <label className="label has-text-white">Zip/Postal Code<strong
                      className="has-text-danger">*</strong></label>
                    <div className="control has-icons-right">
                      <input className={`input ${this.props.seasonalParameters.fields.zip.error ? "is-danger" : ""}`}
                             type="text" placeholder="Zip/Postal Code" name="zip"
                             value={this.props.seasonalParameters.fields.zip.value} onChange={this.handleChange}/>
                      <span className="icon is-small is-right">
                        <i
                          className={`fa fa-exclamation-triangle has-text-danger ${this.props.seasonalParameters.fields.zip.error ? "" : "is-invisible"}`}/>
                      </span>
                    </div>
                    <p
                      className={`help is-danger has-text-danger ${this.props.seasonalParameters.fields.zip.error ? "" : "is-hidden"}`}>{this.props.seasonalParameters.fields.zip.message}</p>
                  </div>
                </div>
              </div>
              <hr className="hr"/>
              <div className="columns is-multiline">
                <div className="column is-4">
                  <div className="field">
                    <label className="label has-text-white">Make of RV<strong
                      className="has-text-danger">*</strong></label>
                    <div className="control has-icons-right">
                      <input
                        className={`input ${this.props.seasonalParameters.fields.makeOfRV.error ? "is-danger" : ""}`}
                        type="text" placeholder="Make of RV" name="makeOfRV"
                        value={this.props.seasonalParameters.fields.makeOfRV.value}
                        onChange={this.handleChange}/>
                      <span className="icon is-small is-right">
                        <i
                          className={`fa fa-exclamation-triangle has-text-danger ${this.props.seasonalParameters.fields.makeOfRV.error ? "" : "is-invisible"}`}/>
                      </span>
                    </div>
                    <p
                      className={`help is-danger has-text-danger ${this.props.seasonalParameters.fields.makeOfRV.error ? "" : "is-hidden"}`}>{this.props.seasonalParameters.fields.makeOfRV.message}</p>
                  </div>
                </div>
                <div className="column is-4">
                  <div className="field">
                    <label className="label has-text-white">Model of RV<strong
                      className="has-text-danger">*</strong></label>
                    <div className="control has-icons-right">
                      <input
                        className={`input ${this.props.seasonalParameters.fields.modelOfRV.error ? "is-danger" : ""}`}
                        type="text" placeholder="Model of RV" name="modelOfRV"
                        value={this.props.seasonalParameters.fields.modelOfRV.value}
                        onChange={this.handleChange}/>
                      <span className="icon is-small is-right">
                        <i
                          className={`fa fa-exclamation-triangle has-text-danger ${this.props.seasonalParameters.fields.modelOfRV.error ? "" : "is-invisible"}`}/>
                      </span>
                    </div>
                    <p
                      className={`help is-danger has-text-danger ${this.props.seasonalParameters.fields.modelOfRV.error ? "" : "is-hidden"}`}>{this.props.seasonalParameters.fields.modelOfRV.message}</p>
                  </div>
                </div>
                <div className="column is-4">
                  <div className="field">
                    <label className="label has-text-white">Year of RV (on Title)<strong
                      className="has-text-danger">*</strong></label>
                    <div className="control has-icons-right">
                      <input
                        className={`input ${this.props.seasonalParameters.fields.yearOfRV.error ? "is-danger" : ""}`}
                        type="text" placeholder="Year of RV (on Title)" name="yearOfRV"
                        value={this.props.seasonalParameters.fields.yearOfRV.value}
                        onChange={this.handleChange}/>
                      <span className="icon is-small is-right">
                        <i
                          className={`fa fa-exclamation-triangle has-text-danger ${this.props.seasonalParameters.fields.yearOfRV.error ? "" : "is-invisible"}`}/>
                      </span>
                    </div>
                    <p
                      className={`help is-danger has-text-danger ${this.props.seasonalParameters.fields.yearOfRV.error ? "" : "is-hidden"}`}>{this.props.seasonalParameters.fields.yearOfRV.message}</p>
                  </div>
                </div>
              </div>
              <hr className="hr"/>
              <div className="field">
                <label className="label has-text-white">Comments & Requests</label>
                <div className="control">
                        <textarea className="textarea" placeholder="Requests"
                                  name="request"
                                  value={this.props.seasonalParameters.fields.request.value}
                                  onChange={this.handleChange}/>
                </div>
              </div>
              <hr className="hr"/>
              <div className={`message
                  ${this.props.seasonalParameters.validation.valid ? "is-hidden" : "is-danger"}`}>
                <div className="message-body">
                  <div className="content">
                    <p>
                      {this.props.seasonalParameters.validation.message}
                    </p>
                  </div>
                </div>
              </div>
              <div className={`message
                  ${this.props.seasonalParameters.response.type === "success" ? "is-success" : "is-danger"}
                  ${this.props.seasonalParameters.response.type ? "" : "is-hidden"}
                  `}>
                <div className="message-body">
                  <div className="content">
                    <p>
                      {this.props.seasonalParameters.response.message}
                    </p>
                  </div>
                </div>
              </div>
              <div className="field is-grouped is-grouped-right">
                <div className="control">
                  <button className="button is-link" onClick={this.handleSubmit}>Send</button>
                </div>
                <div className="control">
                  <button className="button is-link is-light">Cancel</button>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

SeasonalPage.propTypes = {
  actions: PropTypes.object.isRequired,
  seasonalParameters: seasonalParameters.isRequired
};

function mapStateToProps(state) {
  return {
    seasonalParameters: state.seasonalParameters
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(actions, dispatch)
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SeasonalPage);
