import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {ConnectedRouter} from 'connected-react-router';
import {Provider} from 'react-redux';
import {ParallaxProvider} from 'react-scroll-parallax';
import ReactGA from 'react-ga'
import ReactPixel from 'react-facebook-pixel';
import App from './App';

export default class Root extends Component {
  render() {
    const {store, history} = this.props;
    ReactGA.initialize('UA-51510261-2');
    ReactPixel.init('1093075101076355');
    return (
      <Provider store={store}>
        <ConnectedRouter history={history}>
          <ParallaxProvider>
            <App/>
          </ParallaxProvider>
        </ConnectedRouter>
      </Provider>
    );
  }
}

Root.propTypes = {
  store: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired
};
